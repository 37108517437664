import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Modal, Row, Col, Upload, Progress } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import InputText from '@uz/unitz-components-web/InputText';
import { Image } from '@uz/unitz-components-web/Image';
import { Form, FormItem, DatePicker, Input, Checkbox } from 'formik-antd';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import './modal.scss';
import Button from '@uz/unitz-components-web/Button';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import _ from 'lodash';
import i18n from 'i18n-js';
import { datePickerLocaleMapping } from '@vl/mod-utils/datePickerLocaleMapping';

const layout = {
  labelAlign: 'left',
  labelCol: { xs: { span: 24, offset: 0 }, md: { span: 6 } },
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    md: { span: 20, offset: 2 },
  },
};

const CoverPhotoInput = styled(Upload.Dragger)`
  background: ${gstyles.colors.background2};
  .ant-upload.ant-upload-btn {
    padding: 0px !important;
  }
`;

export default function View8({ title }) {
  const locale = i18n.locale;
  return (
    <DIV className="component">
      <Modal
        title={
          <div className="text-main text-xl font-semibold">
            {!!title ? title : ctx.apply('i18n.t', 'ZoomToolMember.Edit_Form.form_name')}
          </div>
        }
        visible={ctx.get('form.isModalVisible')}
        onOk={ctx.get('form.submitForm')}
        onCancel={ctx.get('form.handleCancel')}
        wrapClassName={'my-modal'}
        className="rounded-lg overflow-hidden"
        maskStyle={{ background: gstyles.colors.black300 }}
        destroyOnClose
        centered
        width={688}
        closeIcon={<div className="mt-4 ml-4">{gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}</div>}
        afterClose={() => ctx.apply('modalModel.hide')}
        footer={[
          <Row justify="end" key="footer">
            <Col span={20}>
              <Row gutter={16}>
                <Col span={12}>
                  <Button
                    name="ink"
                    block
                    onClick={ctx.get('form.handleCancel')}
                    loading={ctx.get('form.isSubmitting')}
                  >
                    {ctx.apply('i18n.t', 'ZoomToolMember.Edit_Form.Action.cancel')}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    key="submit"
                    type="primary"
                    block
                    onClick={ctx.get('form.submitForm')}
                    disabled={!ctx.get('form.canSubmit')}
                    loading={ctx.get('form.isSubmitting')}
                  >
                    {!!title ? title : ctx.apply('i18n.t', 'ZoomToolMember.Edit_Form.Action.edit_user')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>,
        ]}
      >
        {ctx.get('form.isModalVisible') && (
          <FormProvider form={ctx.get('form')}>
            <Form {...layout} className="add-form" id="add-form">
              <CustomInput>
                <FormItem
                  name="first_name"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.first_name_label')}
                    </span>
                  }
                  required
                >
                  <InputText
                    name="first_name"
                    placeholder={ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.first_name_placeholder')}
                  />
                </FormItem>
              </CustomInput>
              <CustomInput>
                <FormItem
                  name="last_name"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.last_name_label')}
                    </span>
                  }
                  required
                >
                  <InputText
                    name="last_name"
                    placeholder={ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.last_name_placeholder')}
                  />
                </FormItem>
              </CustomInput>
              <CustomInput>
                <FormItem
                  name="date_of_birth"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.date_of_birth_label')}
                    </span>
                  }
                >
                  <DatePicker
                    name="date_of_birth"
                    allowClear={false}
                    locale={datePickerLocaleMapping(locale)}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    placeholder={ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.date_of_birth_placeholder')}
                  />
                </FormItem>
              </CustomInput>
              <CustomInput>
                <FormItem
                  name="place_of_birth"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.place_of_birth_label')}
                    </span>
                  }
                >
                  <InputText
                    name="place_of_birth"
                    placeholder={ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.place_of_birth_placeholder')}
                  />
                </FormItem>
              </CustomInput>
              <CustomInput>
                <FormItem
                  name="email"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.email_label')}
                    </span>
                  }
                  required
                >
                  <InputText
                    disabled
                    name="email"
                    placeholder={ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.email_placeholder')}
                  />
                </FormItem>
              </CustomInput>
              <CustomInput>
                <FormItem
                  name="phone"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.phone_label')}
                    </span>
                  }
                >
                  <Input
                    name="phone"
                    placeholder={ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.phone_placeholder')}
                  />
                </FormItem>
              </CustomInput>
              <CustomInput>
                <FormItem
                  name="avatar_url"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.photo_label')}
                    </span>
                  }
                >
                  <div className="relative">
                    <CoverPhotoInput
                      className="rounded-lg"
                      {...ctx.apply('form.getUploaderProps', { fieldName: 'avatar_url', multiple: false })}
                      itemRender={() => null}
                    >
                      {!_.isArray(ctx.get('form.values.avatar_url')) ? (
                        <div className="bg-background2 py-8 flex items-center flex-col w-full justify-center">
                          <div className="mb-3">
                            {gstyles.icons({
                              name: 'add_photo',
                              size: 28,
                              fill: gstyles.colors.sub,
                            })}
                          </div>
                          <Button name="ink" size="medium">
                            {ctx.apply('i18n.t', 'ZoomToolCourse.Form.add_photo')}
                          </Button>
                          <div className="py-2 text-sub text-sm">
                            {ctx.apply('i18n.t', 'ZoomToolCourse.Form.drop_photo')}
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center">
                          <Image
                            size="cover"
                            className="w-full"
                            src={ctx.get('form.values.avatar_url.0.url')}
                            style={{ height: 205, objectFit: 'contain' }}
                            alt="test"
                          />
                        </div>
                      )}
                    </CoverPhotoInput>

                    {ctx.get('form.values.avatar_url.0.status') === 'uploading' && (
                      <div className="inset-0 absolute flex items-center justify-center p-4 opacity-50 bg-black">
                        <Progress width={80} type="circle" percent={ctx.get('form.values.avatar_url.0.percent')} />
                      </div>
                    )}
                  </div>
                </FormItem>
              </CustomInput>
              <CustomInput>
                <FormItem
                  name="note"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.note_label')}
                    </span>
                  }
                >
                  <Input.TextArea
                    name="note"
                    rows={3}
                    placeholder={ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.note_placeholder')}
                  />
                </FormItem>
              </CustomInput>
            </Form>
          </FormProvider>
        )}
      </Modal>
    </DIV>
  );
}
