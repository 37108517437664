import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import gstyles from '@vl/gstyles';
import B2BEditSpecificMemberModal from '@uz/unitz-tool-components/B2BEditSpecificMemberModal';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import ActionMenu from '@uz/unitz-components-web/ActionMenu';
import DIV from '@vl/redata/DIV.macro';
import Link from '@uz/unitz-components-web/Link';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),

            filterForm: hook((ctx) => {
              const route = useRoute();
              const locale = _.get(route.getPageContext(), 'lang', '');
              const routeParams = route.getParams();
              const search_data = _.get(routeParams, 'search', '');
              const status = _.get(routeParams, 'status', '');
              const form = useFormik({
                enableReinitialize: true,
                initialValues: {
                  status: status,
                  search: search_data,
                },

                onSubmit: async (values, actions) => {
                  try {
                    const memberFilterPagingModel = ctx.apply('REF.getRef', 'MemberPagingModel');
                    if (memberFilterPagingModel) {
                      await memberFilterPagingModel.onLoadPage(0);
                    }
                    route.navigate(
                      route.to(`/${locale}/accounts/me/members`, {
                        ..._.omit(form.values, ['']),
                      })
                    );
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                clearFilter: () => {
                  form.resetForm();
                },
              });

              return form;
            }),

            getFilterArgs: hook((ctx) => {
              const statusVars = {
                active: true,
                disabled: false,
                all: '',
              };
              return () => {
                const values = ctx.get('filterForm.values', {});
                const status = _.get(values, 'status', '');
                const args = {
                  search: _.get(values, 'search', ''),
                  ...(status ? { status: _.get(statusVars, status, '') } : {}),
                };
                if (status === 'all') _.unset(args, 'status');
                return args;
              };
            }),

            handleChangeSubmit: hook((ctx) => {
              return React.useMemo(() => _.debounce(() => ctx.apply('filterForm.handleSubmit'), 599), []);
            }),

            paginationModel: hook((ctx) => {
              const ref = React.useRef({});
              const account_id = ctx.apply('accountModel.getAccountId');
              _.assign(ref.current, { getFilterArgs: ctx.get('getFilterArgs') });
              const pagingModel = React.useMemo(() => {
                const condition = `where: {
                  account_id: {_eq: "${account_id}"}
                }`;
                const dataQuery = `
                  b2b_search_program (args: $args, ${condition}, order_by: {created_at: desc_nulls_last}){
                    id
                  }
                `;

                const pagingModel = PaginationModel.fromConfig({
                  dataQuery,
                  aggQuery: `
                    b2b_search_program_aggregate (args: $args, ${condition}) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 10,
                  varsList: '$args: b2b_search_member_args!',
                  getVars: () => {
                    return {
                      args: ref.current.getFilterArgs(),
                    };
                  },
                });
                return pagingModel;
              }, [ref, account_id]);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                hasFilterArgs: () => !!_.compact(_.values(ref.current.getFilterArgs())).length,
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };
              ctx.apply('REF.setRef', 'MemberPagingModel', pagination);
              return pagination;
            }),

            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return <div className="text-xs">{ctx.get('paginationModel.page') * 10 + index + 1}</div>;
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolStudent.Table.student')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-3">
                          <Link
                            to={ctx.apply('routeStore.toUrl', 'studentReport', val)}
                            className="text-blue500 text-xs"
                          >
                            {MemberFormatter.fullName(ctx)(val)}
                          </Link>
                          <div className="text-xs">
                            {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.type')}:{' '}
                            {ctx.apply('i18n.t', 'ZoomToolMember.Form.undefined')}
                          </div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.email')}
                    </div>
                  ),
                  key: 'email',
                  render: (val) => <div className="text-xs">{MemberFormatter.email(ctx)(val) || ''}</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.course_in_progress')}
                    </div>
                  ),
                  width: 100,
                  render: (val) => <div className="text-center text-xs">3/15</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.lesson_in_progress')}
                    </div>
                  ),
                  key: 'lessonInProgress',
                  width: 100,
                  render: (val) => <div className="text-center text-xs">5/122</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolTeacher.Table.attendance')}
                    </div>
                  ),
                  width: 40,
                  render: (val) => <div className="text-center text-xs">-/-/-</div>,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.col_name')}
                    </div>
                  ),
                  width: 50,
                  render: (val) => {
                    return (
                      <div className="text-xs">
                        {!!_.get(val, 'is_active')
                          ? ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.active')
                          : ctx.apply('i18n.t', 'ZoomToolMember.Table.status.type.disabled')}
                      </div>
                    );
                  },
                },

                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.action')}
                    </div>
                  ),
                  width: 120,
                  render: (val) => {
                    return (
                      <ActionMenu>
                        <ActionMenu.Item>
                          <ModalProvider>
                            <div
                              style={{ color: gstyles.colors.blue500 }}
                              className="mr-3 underline text-xs text-blue500 cursor-pointer text-center"
                              onClick={() => {
                                ctx.apply('modalModel.show', () => {
                                  return (
                                    <DIV>
                                      {ctx.debug(() => {
                                        ctx.set('@item', val);
                                      })}
                                      <B2BEditSpecificMemberModal />;
                                    </DIV>
                                  );
                                });
                              }}
                            >
                              {ctx.apply('i18n.t', 'ZoomToolMember.Table.Action.edit')}
                            </div>
                          </ModalProvider>
                        </ActionMenu.Item>
                        <ActionMenu.Item>
                          <Link
                            className="underline text-xs text-center"
                            to={ctx.apply('routeStore.toUrl', 'studentReport', val)}
                          >
                            {ctx.apply('i18n.t', 'ZoomToolTeacher.Action.view_report')}
                          </Link>
                        </ActionMenu.Item>
                      </ActionMenu>
                    );
                  },
                },
              ];

              return columns;
            }),

            tableName: hook(() => 'tblStudents'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('paginationModel');
              const tableName = ctx.get('tableName', 'tblStudents');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                pagination: {
                  pageSize: data.pageSize,
                  total: data.total,
                  showLessItems: true,
                  onChange: (page) => {
                    data.onLoadPage(page - 1);
                  },
                },
                dataSource: data.items,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
